import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileAudio,
  faFileExcel,
  faFilePdf,
  faFileZipper,
  faLink,
  faArrowDown,
} from "@fortawesome/pro-light-svg-icons";
import styled from "@emotion/styled";
import { theme } from "../theme";

const ICONS = {
  ".link": faLink,
  ".pdf": faFilePdf,
  ".xlsx": faFileExcel,
  ".mp3": faFileAudio,
  ".zip": faFileZipper,
};

function BaseButton({
  path,
  title,
  publishDate,
  variant,
  color,
  icon,
  extraIcon,
  isDark,
  v2,
}) {
  const mainColor = isDark ? "white" : theme.palette.primary.main;

  return (
    <Button
      variant={variant}
      color={color}
      onClick={() => window.open(path, "_blank")}
      sx={{
        padding: "9px !important",
        height: "36px",
        "> svg > path": {
          color: `${theme.palette.secondary.main} !important`,
        },
      }}
    >
      <FontAwesomeIcon icon={icon} color={mainColor} size={v2 ? "1x" : "2x"} />
      <Box mx={1} textAlign="start">
        {publishDate && (
          <Typography color="neutral.main" variant="comment">
            {publishDate}
          </Typography>
        )}
        <StyledTitle variant="small" sx={{ "& p": { fontSize: "14px", color: (theme) => theme.palette.secondary.main } }}>
          {title}
        </StyledTitle>
      </Box>
      {extraIcon && <FontAwesomeIcon icon={extraIcon} color={mainColor} />}
    </Button>
  );
}

function DownloadPdfButton({
  path,
  title,
  publishDate,
  ext = ".pdf",
  isDark = false,
  variant = "transparent",
  v2 = false,
}) {
  const icon = v2 ? faArrowDown : ICONS[ext];

  return (
    <BaseButton
      path={path}
      title={title}
      publishDate={publishDate}
      ext={ext}
      variant={variant}
      color={isDark ? "white" : "primary"}
      icon={icon}
      extraIcon={v2 ? faFilePdf : null}
      isDark={isDark}
      v2={v2}
    />
  );
}

export default DownloadPdfButton;

const StyledTitle = styled(Typography)(({ theme }) => `
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: ${theme.palette.primary.main};
  ${theme.breakpoints.down("sm")} {
    color: ${theme.palette.primary.main};
  }
`);
