import React from "react";
import styled from "@emotion/styled";
import { Trans } from "@lingui/macro";
import { Box, CircularProgress, Typography } from "@mui/material";
import _ from "lodash";
import share from "../services/share";
import NumeralColor from "./NumeralColor";

const detailsData = () => [
  {
    key: "previousClosePrice",
    title: <Trans>PREVIOUS CLOSE</Trans>,
  },
  {
    key: "tradedVolume",
    title: <Trans>VOLUME</Trans>,
  },
  {
    key: "volumeAmount",
    title: <Trans>VOLUME (AMOUNT)</Trans>,
  },
  {
    key: "openPrice",
    title: <Trans>OPEN</Trans>,
    keyDate: "openAt",
  },
  {
    key: "lowestPrice",
    title: <Trans>LOW</Trans>,
    keyDate: "lowestAt",
  },
  {
    key: "highestPrice",
    title: <Trans>HIGH</Trans>,
    keyDate: "highestAt",
  },
];

function SharePriceData({ isSmall = false }) {
  const [data, setData] = React.useState({});
  const getData = () => share().getPriceData().then((result) => setData(result));

  React.useEffect(() => {
    getData();
    const timer = setInterval(() => getData(), 60000); // 60secs
    return () => clearInterval(timer);
  }, []);

  return (
    <StyledContainer>
      {_.isEmpty(data) ? (
        <ProgressContainer>
          <CircularProgress />
        </ProgressContainer>
      ) : (
        <>
          <Box>
            <Typography fontSize={14} fontWeight={600}>
              ALLFUNDS
            </Typography>
            <Typography fontSize={14}>
              AMS:ALLFG -
              {" "}
              {data.sessionTime}
            </Typography>
          </Box>
          <Box className="data">
            <Box className="main" sx={{ gridColumn: "1/4" }}>
              <Box>
                <NumeralColor className="price" value={data.lastPrice} />
                <Typography ml={0.5}>EUR</Typography>
              </Box>
              <Box>
                <NumeralColor value={data.priceChange} />
                <NumeralColor ml={0.5} value={data.percentChange}>
                  (
                  {data.percentChange}
                  %)
                </NumeralColor>
              </Box>
            </Box>
            {detailsData()
              .splice(0, isSmall ? 3 : 6)
              .map(({ key, title, keyDate }) => (
                <Box>
                  <Box>
                    <Box>
                      <Typography>{title}</Typography>
                      <Typography className="item">{data[key]}</Typography>
                      {keyDate && (
                        <Typography className="grey">
                          {data[keyDate]}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </Box>
              ))}
          </Box>
        </>
      )}
    </StyledContainer>
  );
}

const ProgressContainer = styled(Box)`
  min-height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 !important;
`;

const StyledContainer = styled(Box)(({ theme }) => `
  border: 1px solid ${theme.palette.primary.main};
  border-radius: 5px;
  padding: ${theme.spacing(2)};
  height: 100%;
  max-height: 520px;
  ${theme.breakpoints.up("lg")} {
    min-height: 500px;
  }
  .grey {
    color: ${theme.palette.neutral.dark};
  }
  .data {
    margin-top: 3rem;
    display: grid;
    place-content: center;
    grid-template-columns: repeat(3, 1fr);
    .main {
      grid-column: 1/4;
      margin: 1rem 0;
      > div {
        display: flex;
        justify-content: center;
        align-items: baseline;
      }
      .price {
        font-size: 3rem;
      }
      * {
        font-size: 1rem;
      }
    }
    > div:not(.main) {
      border-top: 1px solid ${theme.palette.neutral.light};
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding: 0.75rem 0;
      min-height: 80px;
      height: 100%;
      > div {
        margin: 0 !important;
        padding: 0 0.75rem;
        min-height: 5rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      :nth-child(3n - 1),
      :nth-child(3n - 3) {
        > div {
          width: 100%;
          border-right: 1px solid ${theme.palette.neutral.light};
        }
      }
      .item {
        font-size: 1rem;
        font-weight: 600;
      }
      * {
        font-size: 16px;
      }
    }
  }
`);

export default SharePriceData;
