import { Trans, t } from "@lingui/macro";
import { Box, Grid, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import styled from "@emotion/styled";
import LandingLayout from "../../components/landings/LandingLayout";
import HeroLanding from "../../components/HeroLanding";
import SecondaryButton from "../../components/SecondaryButton";
import { theme } from "../../themeV2";
import ProductModal from "../../components/ProductModal";
import DefaultSection from "../../components/landings/DefaultSection";
import CardColor from "../../components/CardColor";
import Container from "../../components/Container";
import sec1Img from "../../images/solutions/regulatory-solutions/sec-1.png";
import sec1Icon1 from "../../images/solutions/regulatory-solutions/sec-1_icon-1.png";
import sec1Icon2 from "../../images/solutions/regulatory-solutions/sec-1_icon-2.png";
import sec1Icon3 from "../../images/solutions/regulatory-solutions/sec-1_icon-3.png";
import sec2IconOrange from "../../images/solutions/regulatory-solutions/icon-orange.svg";
import sec2IconPurple from "../../images/solutions/regulatory-solutions/icon-purple.svg";
import sec2IconGreen from "../../images/solutions/regulatory-solutions/icon-green.svg";
import sec3VideoPoster from "../../images/products/share-class-radar/search-video-poster.png";
import sec4Img from "../../images/solutions/regulatory-solutions/sec-4.png";
import sec5Img from "../../images/solutions/regulatory-solutions/sec-5.png";
import sec5Icon1 from "../../images/solutions/regulatory-solutions/sec-5_icon-1.png";
import sec5Icon2 from "../../images/solutions/regulatory-solutions/sec-5_icon-2.png";

const metadata = {
  title: "Regulatory Solutions",
  description:
    "The regulatory landscape for financial services is constantly evolving, making it challenging to keep pace with the latest requirements.",
};

const mainColor = theme.palette.colors.orange.dark;

const SEC_1_ITEMS = [
  {
    icon: sec1Icon1,
    title: t`Performance Evolution`,
    text: t`Leverage our comprehensive database for accurate PRIIPs KID completion with crucial information such as NAV history and cost details.`,
  },
  {
    icon: sec1Icon2,
    title: t`Efficient Validation Process`,
    text: t`Utilize our advanced control and monitoring tools to validate PRIIPs KID, ensuring accuracy and compliance every step of the way.`,
  },
  {
    icon: sec1Icon3,
    title: t`Direct Distribution `,
    text: t`Allfunds connects fund houses directly with distributors for seamless document dissemination and accurate updates.`,
  },
];

const SEC_2_CARDS_ITEMS = [
  {
    icon: sec2IconGreen,
    color: theme.palette.colors.green.main,
    title: t`EMT`,
    description: t`Easily comply with MiFID II regulations, ensuring all necessary information is accurately captured and reported.`,
  },
  {
    icon: sec2IconOrange,
    color: theme.palette.colors.orangeLight.main,
    title: t`EPT`,
    description: t`Streamline your obligations under the PRIIPs regulation with tailored assistance designed to meet all reporting requirements.`,
  },
  {
    icon: sec2IconPurple,
    color: theme.palette.colors.purple.main,
    title: t`EET`,
    description: t`Transparently showcase your funds' sustainable profiles by leveraging the EET, meeting the increasing demand for ESG information and compliance.`,
  },
];

const SEC_5_ITEMS = [
  {
    icon: sec5Icon1,
    title: t`Extensive Global Coverage`,
    description: t`Our registration services cover more than 70 countries, including EU-EEA countries, APAC, Latam, and the Middle East, providing unmatched access to diverse markets.`,
  },
  {
    icon: sec5Icon2,
    title: t`Seamless Integration and Support`,
    description: t`Our platform integrates seamlessly with your existing operations, offering continuous support to manage registrations efficiently.`,
  },
];

function RegulatorySolutions() {
  const [showModal, setShowModal] = useState(false);
  const [subTitle, setSubTitle] = useState(null);

  const handleOnclickInfo = (key) => {
    setSubTitle(key);
    setShowModal(true);
  };

  const renderRequestButton = (key) => {
    return (
      <SecondaryButton
        handleOnclickInfo={() => handleOnclickInfo(key)}
        text={<Trans>Request info</Trans>}
      />
    );
  };

  const renderCard = ({ color, icon, title, description }) => {
    return (
      <Grid
        item
        xs={12}
        md={4}
      >
        <CardColor
          mt={0}
          color={color}
          icon={icon}
          title={title}
          description={description}
        />
      </Grid>
    );
  };

  return (
    <LandingLayout
      seoTitle={metadata.title}
      seoDescription={metadata.description}
    >
      <HeroLanding
        title={<Trans>Streamline Your Regulatory Compliance </Trans>}
        description={
          <Trans>
            The regulatory landscape for financial services is constantly
            evolving, making it challenging to keep pace with the latest
            requirements.
            <br />
            <br />
            Allfunds offers a comprehensive suite of regulatory solutions
            designed to simplify compliance and empower your business.
          </Trans>
        }
        handleOnclickInfo={() => handleOnclickInfo("Regulatory Solutions")}
        isVideo={true}
        multimedia="https://app.allfunds.com/docs/cms/hero_b8453f951b.mp4"
        widthImage="100%"
        kind="Regulatory Solutions"
        kindColor={mainColor}
        grid={{
          columnLeft: { md: 6.5 },
          columnRight: { md: 5.5 },
        }}
      />
      <DarkStack
        component="span"
        backgroundColor={theme.palette.colors.blue.dark}
        borderRadius="48px"
      >
        <DefaultSection
          containerPy={5}
          pretitle={t`Regulatory Reporting`}
          pretitleColor={mainColor}
          color={theme.palette.colors.white.main}
          title={t`Streamline Your Reporting Needs`}
          description={
            <>
              <Typography>
                <Trans>
                  Our platform goes beyond basic compliance, offering a suite of
                  tools for effective regulatory reporting:
                </Trans>
              </Typography>
              <Grid
                container
                spacing={2}
                ml={{ xs: "-24px!important", md: "-36px!important" }}
                mt="-12px!important"
                pb={{ md: 2 }}
                order={{ xs: 0, md: 1 }}
              >
                {SEC_1_ITEMS.map((item, key) => (
                  <Grid item key={key} md={6} xs={12}>
                    <Stack spacing={1} textAlign="left">
                      <img
                        src={item.icon}
                        alt="Regulatory Solutions - Allfunds"
                        width="38"
                        height="38"
                        style={{ objectFit: "contain" }}
                      />
                      <Typography fontWeight="700" lineHeight="1.1">
                        {item.title}
                      </Typography>
                      <Typography>{item.text}</Typography>
                    </Stack>
                  </Grid>
                ))}
              </Grid>
            </>
          }
          multimedia={sec1Img}
        />
        <Container component="section" pb={5}>
          <Grid container spacing={{ xs: 1, md: 2 }}>
            {SEC_2_CARDS_ITEMS.map((item) => renderCard(item))}
          </Grid>
          <Box
            mt={{ xs: 2, md: 5 }}
            display="flex"
            justifyContent={{ xs: "center", sm: "flex-start", md: "center" }}
          >
            {renderRequestButton("Share Class Search")}
          </Box>
        </Container>
      </DarkStack>
      <DefaultSection
        pretitle={t`Share Class Search`}
        direction={{ xs: "column-reverse", md: "row-reverse" }}
        pretitleColor={mainColor}
        title={t`Powerful Fund Class Search`}
        buttons={renderRequestButton("Share Class Search")}
        isVideo={true}
        videoPoster={sec3VideoPoster}
        multimedia="https://app.allfunds.com/docs/cms/search_video_3c50f7d17b.mp4"
        description={
          <ul>
            <li>
              <Trans>Search by ISIN, Fund House, and Family Group</Trans>
            </li>
            <li>
              <Trans>Upload Excel lists for instant information</Trans>
            </li>
            <li>
              <Trans>Save selections for regulatory compliance</Trans>
            </li>
            <li>
              <Trans>
                Integrated with Allfunds Connect for intuitive selection
              </Trans>
            </li>
            <li>
              <Trans>Access the most accurate data sources</Trans>
            </li>
            <li>
              <Trans>
                Ongoing charges, Distribution, management, subscription, and
                redemption fees
              </Trans>
            </li>
            <li>
              <Trans>Calculate total NET cost in a few clicks</Trans>
            </li>
          </ul>
        }
      />
      <DarkStack
        component="span"
        backgroundColor={theme.palette.colors.blue.dark}
        borderRadius="48px 48px 0 0"
        py={{ xs: 2, sm: 4, md: 5 }}
      >
        <DefaultSection
          direction={{ xs: "column-reverse", md: "row-reverse" }}
          color={theme.palette.colors.white.main}
          pretitle={t`Fund Registration`}
          pretitleColor={mainColor}
          title={t`One Stop Shop Solution for Fund Registration Services`}
          imgStyles={{ width: "80%", display: "block", margin: "0 auto" }}
          description={
            <>
              <Typography>
                <Trans>
                  Navigating the complexities of fund registration can be
                  daunting, but Allfunds simplifies this process with our
                  all-in-one solution. Our platform provides:
                </Trans>
              </Typography>
              <ul>
                <li style={{ marginBottom: "12px" }}>
                  <Trans>
                    <b>Comprehensive Regulatory Information:</b> Stay informed
                    with the latest regulatory updates and requirements needed
                    to register your funds in any new country.
                  </Trans>
                </li>
                <li style={{ marginBottom: "12px" }}>
                  <Trans>
                    <b>In-Depth Insights on Regulatory Changes:</b> Keep your
                    finger on the pulse of new regulatory requirements that
                    could impact your fund distribution strategy, ensuring
                    compliance and seamless market entry.
                  </Trans>
                </li>
                <li>
                  <Trans>
                    <b>Tailored Guidance and Support:</b> Benefit from
                    personalized assistance and expert advice tailored to your
                    specific needs and market conditions.
                  </Trans>
                </li>
              </ul>
            </>
          }
          multimedia={sec4Img}
        />
        <DefaultSection
          color={theme.palette.colors.white.main}
          title={t`Supporting UCITS and AIF Fund Managers Worldwide`}
          grid={{
            columnRight: {
              md: 5,
            },
            columnLeft: {
              md: 7,
            },
          }}
          description={
            <Stack>
              <Typography mb={2}>
                <Trans>
                  With Allfunds, fund managers of UCITS and AIF have the tools
                  and support needed to reach a global audience. Our services
                  include:
                </Trans>
              </Typography>
              <Grid
                container
                spacing={{ xs: 2, md: 4 }}
                ml={{ xs: "-24px!important", md: "-48px!important" }}
                justifyContent="center"
                order={{ xs: 0, md: 1 }}
              >
                {SEC_5_ITEMS.map((item, key) => (
                  <Grid item key={key} sm={6} xs={12}>
                    <Stack spacing={1} textAlign="left">
                      <img
                        src={item.icon}
                        alt="Regulatory Solutions - Allfunds"
                        width="38"
                        height="38"
                        style={{ objectFit: "contain" }}
                      />
                      <Typography fontWeight="700" lineHeight="1.1" pb={1}>
                        {item.title}
                      </Typography>
                      <Typography>{item.description}</Typography>
                    </Stack>
                  </Grid>
                ))}
              </Grid>
            </Stack>
          }
          multimedia={sec5Img}
          buttons={renderRequestButton("Fund Managers")}
        />
      </DarkStack>
      {showModal && (
        <ProductModal
          onClose={() => setShowModal(false)}
          subject="Regulatory Solutions"
          product="regulatory-solutions"
          hasTemplate={false}
          subtitle={subTitle}
        />
      )}
    </LandingLayout>
  );
}

const DarkStack = styled(Stack)`
  .sec-1_tab img {
    @media screen and (min-width: 1200px) {
      padding-right: 24px;
    }
   };
  
  p:not(.preTitle) {color: ${theme.palette.colors.white.main};
`;

export default RegulatorySolutions;
