import { Trans, t } from "@lingui/macro";
import { Grid, Link, Stack, Typography } from "@mui/material";
import styled from "@emotion/styled";
import React, { useState } from "react";
import LandingLayout from "../../components/landings/LandingLayout";
import HeroLanding from "../../components/HeroLanding";
import SecondaryButton from "../../components/SecondaryButton";
import { theme } from "../../themeV2";
import ProductModal from "../../components/ProductModal";
import DefaultSection from "../../components/landings/DefaultSection";
import CardColor from "../../components/CardColor";
import sec1Tab2 from "../../images/products/financial-market-portal/sec-1_tab-2.png";
import sec1Tab3 from "../../images/products/financial-market-portal/sec-1_tab-3.png";
import sec3Slide1 from "../../images/products/financial-market-portal/sec-3_slide-1.png";
import sec3Slide2 from "../../images/products/financial-market-portal/sec-3_slide-2.png";
import sec3Slide3 from "../../images/products/financial-market-portal/sec-3_slide-3.png";
import sec3Slide4 from "../../images/products/financial-market-portal/sec-3_slide-4.png";
import sec3Slide5 from "../../images/products/financial-market-portal/sec-3_slide-5.png";
import iconBlue from "../../images/products/financial-market-portal/sec-2_icon-blue.svg";
import iconGreen from "../../images/products/financial-market-portal/sec-2_icon-green.svg";
import iconOrange from "../../images/products/financial-market-portal/sec-2_icon-orange.svg";
import iconYellow from "../../images/products/financial-market-portal/sec-2_icon-yellow.svg";
import iconPurple from "../../images/products/financial-market-portal/sec-2_icon-purple.svg";
import LandingsTabs from "../../components/landings/LandingsTabs";
import ImageComponent from "../../components/landings/ImageComponent";

const metadata = {
  title: "Fund Market Portal",
  description:
    "Our financial markets portal delivers fund and market data, reporting tools, data analytics, ESG solutions, and portfolio management tools to find, analyze, compare, select the best strategies.",
};

const CARDS_ITEMS = [
  {
    order: { xs: 1, md: 1 },
    icon: iconYellow,
    color: theme.palette.colors.yellow.main,
    title: <Trans>Streamline Portfolio Construction</Trans>,
    description: (
      <Trans>
        Easily build and manage client and model portfolios with intuitive
        planning and reporting tools.
      </Trans>
    ),
  },
  {
    order: { xs: 3, md: 2 },
    icon: iconPurple,
    color: theme.palette.colors.purple.main,
    title: <Trans>Enhance Efficiency</Trans>,
    description: (
      <Trans>
        Integrate your clients’ existing portfolios or upload them in bulk to
        automate your workflow. Set up automated alerts to stay informed of
        critical changes in your portfolios.
      </Trans>
    ),
  },
  {
    order: { xs: 4, md: 3 },
    icon: iconBlue,
    color: theme.palette.colors.blue.chart,
    title: <Trans>Meet Regulatory Requirements</Trans>,
    description: (
      <Trans>
        Effortlessly generate comprehensive reports and fulfill compliance
        obligations with built-in tools that simplify regulatory processes.
      </Trans>
    ),
  },
  {
    order: { xs: 2, md: 4 },
    icon: iconOrange,
    color: theme.palette.colors.orangeLight.main,
    title: <Trans>Gain Deeper Insights</Trans>,
    description: (
      <Trans>
        Track and analyze your portfolios in real-time with value-added data
        from Allfunds. Access powerful analytics, including performance
        evolution, asset allocation, performance attribution, and ESG analysis.
      </Trans>
    ),
  },
  {
    order: { xs: 5 },
    icon: iconGreen,
    color: theme.palette.colors.green.main,
    title: <Trans>Unlock the Power of Allfunds</Trans>,
    description: (
      <Trans>
        Leverage the extensive data and insights of the Allfunds platform to
        optimize your investment strategies and enhance client service.
      </Trans>
    ),
  },
];

const SEC_1_ITEMS = [
  {
    video: "https://app.allfunds.com/docs/cms/sec_1_tab_1_09f794b6e7.mp4",
    direction: { xs: "column-reverse", md: "row-reverse" },
    tabTitle: t`Performance Evolution`,
    pretitle: t`Screener`,
    title: t`An easy-to-use multi-criteria screener that quickly finds the best strategies for you`,
    description: (
      <Typography>
        <Trans>
          Filter through +300,000 funds, ETFs and stocks by performance, risk,
          ESG factors, and more to pinpoint investment opportunities that align
          with your goals.
        </Trans>
      </Typography>
    ),
  },
  {
    img: sec1Tab2,
    tabTitle: t`Comparison`,
    pretitle: t`Fund Comparison`,
    title: t`In-depth comparison that goes beyond the surface`,
    description: "",
  },
  {
    img: sec1Tab3,
    direction: "row-reverse",
    tabTitle: t`Watchlist`,
    pretitle: t`Watchlist`,
    title: t`Curated watchlists to stay ahead of the curve`,
    description: (
      <Typography>
        <Trans>
          Monitor your favorite assets and discover new opportunities. Create
          personalized watchlists, track performance, and receive alerts to stay
          informed and seize the right moment.
        </Trans>
      </Typography>
    ),
  },
];

const SEC_3_SLIDER = [
  {
    image: sec3Slide1,
    url: "https://next.allfunds.com/apps/fund-insights/all_regions/710/articles/3489/ai%3A-balancing-this-revolutionary-technology%E2%80%99s-opportunities-and-risks",
    title:
      t`AI: Balancing this revolutionary technology’s opportunities and risks`,
    date: "2024-11-07",
    content: (
      <Typography>
        <Trans>
          In a series of notes, we’ve explored the genesis of artificial intelligence (AI), the infrastructure required to support this revolutionary technology, and the functions in which it can be...
        </Trans>
      </Typography>
    ),
  },
  {
    image: sec3Slide2,
    url: "https://next.allfunds.com/apps/fund-insights/italy/905/articles/3503/apac-cio-view%3A-future-opportunities-in-asean-market",
    title: t`APAC CIO View: Future Opportunities in ASEAN Market`,
    date: "2024-11-05",
    content: (
      <Typography>
        <Trans>
          In the latest Alternative Allocations podcast, Kate Huntington of
          Fiduciary Trust International discussed family offices' approach to
          alternative investments.
        </Trans>
      </Typography>
    ),
  },
  {
    image: sec3Slide3,
    url: "https://next.allfunds.com/apps/fund-insights/all_regions/905/articles/3294/the-corporate-green-bond-renaissance",
    title: t`The corporate green bond renaissance`,
    date: "2024-09-26",
    content: (
      <Typography>
        <Trans>
          Green bonds, social bonds, sustainability bonds and
          sustainability-linked bonds have grown a combined 43% CAGR between
          2014 and 2023 to represent approximately 14% of the global fixed
          income market...
        </Trans>
      </Typography>
    ),
  },
  {
    image: sec3Slide4,
    url: "https://next.allfunds.com/apps/fund-insights/all_regions/383/articles/3329/outlook-for-direct-lending",
    title: t`Outlook for Direct Lending`,
    date: "2024-10-21",
    content: (
      <Typography>
        <Trans>
          As banks have gradually retreated from middle-market lending, and
          public capital markets have skewed larger and exhibited volatility,
          private credit has stepped in to fill the void. Private credit..
        </Trans>
      </Typography>
    ),
  },
  {
    image: sec3Slide5,
    url: "https://next.allfunds.com/apps/fund-insights/all_regions/189/articles/3312/accessing-private-assets%3A-one-structure-doesn%E2%80%99t-fit-all",
    title: t`Accessing private assets: One structure doesn’t fit all`,
    date: "2024-10-25",
    content: (
      <Typography>
        <Trans>
          The drive to make private markets more accessible for the wealth
          channel is a big focus for the asset management industry globally.
          Private markets are seen as a way to deliver better investment...
        </Trans>
      </Typography>
    ),
  },
];

function FinancialMarketPortal() {
  const [subTitle, setSubTitle] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedTab, setSelectedTab] = useState(SEC_1_ITEMS[0].tabTitle);

  const handleOnclickInfo = (key) => {
    setSubTitle(key);
    setShowModal(true);
  };

  const renderRequestButton = (key) => {
    return (
      <SecondaryButton
        handleOnclickInfo={() => handleOnclickInfo(key)}
        text={<Trans>Request info</Trans>}
      />
    );
  };

  const renderCard = ({ color, icon, title, description, key, order }) => {
    return (
      <Grid
        item
        xs={12}
        md={4}
        sx={{
          transform: {
            md:
              key === 1
                ? "translateY(50%)"
                : key === 4
                ? "translateX(100%)"
                : null,
          },
          textAlign: "left",
        }}
        order={order || key}
      >
        <CardColor
          mt={0}
          color={color}
          icon={icon}
          title={title}
          description={description}
        />
      </Grid>
    );
  };

  const slideContent = ({ ...item }, index) => {
    return (
      <Stack key={index} py={2} px={1}>
        <Stack
          key={index}
          sx={{
            borderRadius: "10px",
            boxShadow: "3px 4px 16px 0px rgba(0, 0, 0, 0.10)",
            backgroundColor: "#fff",
            paddingBottom: 2,
            overflow: "hidden",
            height: { xs: "450px", sm: "350px", md: "400px", lg: "460px" },
          }}
        >
          <Link to={item.ulr}>
            <ImageComponent
              src={item.image}
              alt={`Carousel img ${index + 1} | Allfunds`}
              extraStyles={{
                objectFit: "contain",
                maxWidth: "100%",
                height: "150px",
              }}
            />
          </Link>
          <Typography fontWeight="700" mt={1} mx="16px">
            {item.title}
          </Typography>
          <Typography mt={0.5} mx="16px">
            {item.date}
          </Typography>
          <Typography mt={1} mx="16px">
            {item.content}
          </Typography>
        </Stack>
      </Stack>
    );
  };

  const selectedItem = SEC_1_ITEMS.find((item) => item.tabTitle === selectedTab);

  return (
    <LandingLayout seoTitle={metadata.title} seoDescription={metadata.description}>
      <HeroLanding
        title={<Trans>Unleash the Power of Informed Investing</Trans>}
        description={
          <Trans>
            Our financial markets portal delivers fund and market data,
            reporting tools, data analytics, ESG solutions, and portfolio
            management tools to find, analyze, compare, select the best
            strategies.
            <br />
            <br />
            Designed specifically for professional investors, it provides
            everything they need.
          </Trans>
        }
        handleOnclickInfo={() => handleOnclickInfo("Financial Market Portal")}
        isVideo={true}
        multimedia="https://app.allfunds.com/docs/cms/hero_b8453f951b.mp4"
        widthImage="100%"
        kind="Financial Markets Portal"
        kindColor={theme.palette.colors.green.main}
        grid={{
          columnLeft: { md: 6.5 },
          columnRight: { md: 5.5 },
        }}
      />
      <Stack component="span" backgroundColor={theme.palette.colors.white.dark} borderRadius="48px">
        <DefaultSection
          textAlign="center"
          containerPy={4}
          pretitleAlign="center"
          pretitle={t`Fund and Markets Data`}
          pretitleColor={theme.palette.colors.green.main}
          title={<Trans>CAST the funds: <br />Compare, Analyse, Select and Track</Trans>}
          description={
            <>
              <Stack alignItems="center">
                <Typography pb={1} width={{md: "75%"}}>
                  <Trans>
                    +300,000 mutual funds, ETFs and stocks are at your fingertips
                    with our powerful, first-class tools such as our screener,
                    watchlist and product comparison
                  </Trans>
                </Typography>
              </Stack>
              <LandingsTabs
                value={selectedTab}
                valueSet={setSelectedTab}
                tabs={SEC_1_ITEMS.map((item) => item.tabTitle)}
                tabColor={theme.palette.colors.green.main}
                tabBgColor={theme.palette.colors.green.light}
            />
              <CustomDefaultSection
                textAlign="left"
                padding="0!important"
                direction={selectedItem.direction}
                title={selectedItem.title}
                pretitle={selectedItem.pretitle}
                description={selectedItem.description}
                pretitleColor={theme.palette.colors.green.main}
                isVideo={!!selectedItem.video}
                multimedia={
                selectedItem.video ? selectedItem.video : selectedItem.img
              }
                buttons={renderRequestButton(selectedItem.tabTitle)}
                buttonsJustify={{ xs: "flex-start" }}
            />
            </>
        }
      />
      </Stack>
      <DefaultSection
        textAlign="center"
        pretitleAlign="center"
        pretitle={t`My Portfolio`}
        pretitleColor={theme.palette.colors.green.main}
        title={t`A perfectly designed, end-to-end portfolio solution for asset management`}
        buttons={renderRequestButton("My Portfolio")}
        description={
          <>
            <Typography>
              <Trans>
                Take control of your investments leveraging Nextportfolio technology, a cutting-edge portfolio managemen solution designed for wealth management professionals and private banks. Built with best-in-class components,<br />
                My Portfolio empowers you to:
              </Trans>
            </Typography>
            <Grid
              container
              spacing={2}
              ml="-24px!important"
              pb={{ sm: 2, md: 0 }}
            >
              {CARDS_ITEMS.map((item, key) => renderCard({ ...item, key }))}
            </Grid>
          </>
        }
      />
      <Stack
        component="span"
        backgroundColor={theme.palette.colors.white.dark}
        borderRadius="48px 48px 0 0"
      >
        <DefaultSection
          pretitle={t`Market News`}
          buttons={renderRequestButton("Market News")}
          pretitleColor={theme.palette.colors.green.main}
          title={t`Stay Ahead with Real-Time News and Insights`}
          description={
            <>
              <Typography>
                <Trans>
                  Access a constant stream of market-moving information to make
                  smarter investment decisions. Our comprehensive news section
                  provides:
                </Trans>
              </Typography>
              <Grid
                container
                spacing={2}
                ml="-24px!important"
                mb="24px!important"
              >
                <Grid item xs={12} sm={6}>
                  <Typography fontWeight="700">
                    <Trans>Extensive Coverage</Trans>
                  </Typography>
                  <Typography>
                    <Trans>
                      Breaking news on stocks, bonds, funds, ETFs, commodities,
                      currencies, and more.
                    </Trans>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography fontWeight="700">
                    <Trans>Extensive Coverage</Trans>
                  </Typography>
                  <Typography>
                    <Trans>
                      Breaking news on stocks, bonds, funds, ETFs, commodities,
                      currencies, and more.
                    </Trans>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography fontWeight="700">
                    <Trans>Trusted Sources</Trans>
                  </Typography>
                  <Typography>
                    <Trans>
                      Information from leading news agencies, top asset managers
                      and expert analysts.
                    </Trans>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography fontWeight="700">
                    <Trans>In-depth Analysis</Trans>
                  </Typography>
                  <Typography>
                    <Trans>
                      Articles and commentary to help you understand the latest
                      market trends.
                    </Trans>
                  </Typography>
                </Grid>
              </Grid>
            </>
          }
          isSlider={true}
          sliderColor={theme.palette.colors.green.main}
          sliderSettings={{
            speed: 500,
            dots: true,
            arrows: false,
            autoplay: true,
            infinite: true,
            slidesToShow: 2,
            cssEase: "linear",
            autoplaySpeed: 2000,
            responsive: [
              {
                breakpoint: 1200,
                settings: {
                  slidesToShow: 1,
                },
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 1,
                },
              },
            ],
          }}
          slidesContent={SEC_3_SLIDER.map((item, index) =>
            slideContent(item, index),
          )}
        />
      </Stack>
      {showModal && (
        <ProductModal
          onClose={() => setShowModal(false)}
          subject="Fund Market Portal"
          product="fund-market-portal"
          hasTemplate={false}
          subtitle={subTitle}
        />
      )}
    </LandingLayout>
  );
}

const CustomDefaultSection = styled(DefaultSection)`
  video {
    mix-blend-mode: multiply;
  }
`;

export default FinancialMarketPortal;
